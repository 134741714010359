/* Sidebar.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.sidebar {
  max-width: 300px;
  width: 100%;
  background-color: #18191b;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-text);
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  margin-top: 65px;
  padding-top: 10px;
  padding-bottom: 60px;
  
  font-weight: 400;
  /* border-radius: 0px 20px 0 0; */
}
.sidebar-nav h3 {
  columns: black;
}
.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.light {
  /* --sidebar-bg: #46484b;
  --sidebar-text: #ffffff; */
   background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
}

.sidebar.dark {
  /* --sidebar-bg: #46484b;
  --sidebar-text: #ffffff; */
  background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
}

.sidebar-logo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
}
.sidebar hr {
  margin: 0px 0 10px 0;
}
.logo-text {
  font-size: 24px;
}
.sideh3 {
  font-size: 1.3rem;
  color: #25d366;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin: 0px 0 10px 0;
padding-bottom: 10px;
padding-top: 20px;
}
.sidebar-divider {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
}

.sidebar-nav-item {
  margin-bottom: 10px;
}

.sidebar-nav-link {
  /* text-decoration: none;
    color: inherit;
    padding: 8px 12px;
    display: block;
    border-radius: 4px;
    transition: background-color 0.3s; */
  text-decoration: none;
  color: inherit;
  padding: 8px 12px;
  display: flex; /* Change from block to flex */
  align-items: center; /* Align icon and text vertically */
  border-radius: 4px;
  transition: background-color 0.3s;
  padding-left: 20px; /* Ensure consistent padding */
}
.sidebar-nav-link .svg-inline--fa {
  margin-right: 20px; /* Consistent margin between icon and text */
  min-width: 20px; /* Minimum width for icons, adjust as needed */
  text-align: center; /* Ensure icons are centered in their space */
  font-size: 18px; /* Optional: Set a consistent icon size */
}

.sidebar-nav-item.active .sidebar-nav-link {
  background-color: #939393;
  color: white;
}

.sidebar-nav-item.active .sidebar-nav-link:hover {
  background-color: #b7b7b7;
}

/* Sidebar.css */
.sidebar-submenu-link {
  text-decoration: none;
  color: inherit;
  padding: 6px 35px;
  display: flex; /* Ensure submenu links are also aligned */
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.sidebar-nav-link:hover {
  background-color: #4e4d4d2e;
  text-decoration: none;
  color: rgb(0, 160, 53);
}

.sidebar-user {
  position: relative;
}

.sidebar-user-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.sidebar-user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sidebar-user-name {
  font-weight: bold;
}

.sidebar-user-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 50px;
  right: 0;
  background: var(--sidebar-bg);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 150px;
  z-index: 10;
  transform: translateY(-20%);
  border: 1px solid white;
}

.sidebar-user-menu-item {
  text-decoration: none;
  color: inherit;
  padding: 8px 12px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.sidebar-user-menu-item:hover {
  background-color: #007af5;
  border-left:1px solid #25d366;
}

.sidebar-user-menu-divider {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.hamburger-button {
  position: fixed;
  top: 68px;
  left: 0px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100;
  display: none;
  font-weight: 800;
  color: #059c3f;
}

.hamburger-button.open {
  display: block;
}
.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;

}
/* Responsive Design */
.sidebar-menu-item {
  display: flex;
  justify-content: space-between; /* Space between icon + menu text and the arrow */
  align-items: center;
  width: 100%;
}

.chevron-icon {
  margin-left: auto; /* Push the arrow to the far right */
  transition: transform 0.3s ease; /* Optional smooth transition */
}

.sidebar-nav-link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.sidebar-nav-item.open > .sidebar-nav-link .chevron-icon {
  transform: rotate(
    180deg
  ); /* Rotate the arrow down when the submenu is open */
}


/* Sidebar.css */

.sidebar-nav-item.active .sidebar-nav-link {
  background-color: #939393;
  color: white;
}

.sidebar-nav-item.active .sidebar-nav-link:hover {
  background-color: #b7b7b7;
}

/* Sidebar.css */

.sidebar-submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 15px;
  margin-top: 10px;
  overflow: hidden;
  animation: slideDown 0.3s ease-in-out;
}

.sidebar-submenu-item {
  margin-bottom: 8px;
}

.sidebar-submenu-link {
  text-decoration: none;
  color: inherit;
  padding: 6px 35px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: none;

  width: 100%;
  text-align: left;
}

.sidebar-submenu-link:hover {
  background-color: #7c7c7c;
  color: #25d366;
  text-decoration: none;
}

.sidebar-nav-item.open .sidebar-nav-link {
  background-color: #939393;
  color: rgb(12, 12, 12);
}

.sidebar-nav-item.open .sidebar-nav-link:hover {
  background-color: #b7b7b7;
}
.chevron-icon {
  transition: transform 0.3s ease;
  display: inline-block;
 
  margin-left: auto; /* Push the arrow to the far right */
 
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sidebar-submenu-item.active .sidebar-submenu-link {
  background-color: #939393; /* Highlight color for active submenu */
  color: white;
}

.sidebar-submenu-item.active .sidebar-submenu-link:hover {
  background-color: #b7b7b7;
}
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);

    margin-top: 0;
    padding-top: 10px;
    height: 90vh;
    padding-bottom: 0px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  /* .hamburger-button {
    display: block;
  }
  .hamburger-button {
    position: fixed;
    top: 10px;

    background: transparent;
  
    color: #059c3f;
} */
 .hamburger-button {
    display: block;
    position: fixed;
    top: 8px;
    right: -300px; /* Positions the button on the right side */
    background: transparent;
    color: #059c3f;
    margin-right: 20px;
}


  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }
  .sidebar-nav {
    margin-top: 50px;
  }
}

.modal-backdroptl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.349);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-contenttl {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.267);
    position: relative;
    min-width: 300px;
}

.modal-bodytl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-spinnertl {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.checkmark-containertl {
    width: 40px;
    height: 40px;
    position: relative;
    margin-bottom: 20px;
}

.checkmarktl {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #4CAF50;
    position: relative;
}

.checkmarktl::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 20px;
    border: solid white;
    border-width: 0 4px 4px 0;
    top: 8px;
    left: 10px;
    transform: rotate(45deg);
}

h2 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.close-btntl {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: #fff;
 
}