.report-container {
  padding: 20px;
  overflow-x: hidden;
  
}
.repfull{
    padding-top: 0px;

    
}
.card-container {
    display: flex;
   justify-content:start;
    gap: 20px;
    /* background-color: rgb(255, 255, 255); */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
        border: 1px solid #78b7ff;
        background-color: white;
   
}
.tabheadert{
    
}
.containersd{
    display: flex;
   gap: 10px;
}
.cardtre {
    flex: 1;
    background-color: #e7f3fe;
    border: 2px solid #78b7ff;
    border-radius: 8px;
    padding: 20px;
   border: 1px solid white;
    text-align: left;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Gradient background */
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    transition: transform 0.2s; /* Animation effect on hover */

}
#dashcards{
    border: 1px solid rgb(163, 162, 247);
    margin-bottom: 0;
}
#tabsicar{
    margin-bottom: 0;
}
.cardtre h2{
    color: #02438d;
}
.cardtre:hover {
    box-shadow: 0px 4px 12px rgb(169, 228, 255);
    border:1px solid #5ca8ff ;

    transform: translateY(-5px); /* Lift effect on hover */
}
.cardtre:active {
    box-shadow: 0px 4px 12px rgb(169, 228, 255);
    border:3px solid #5ca8ff ;
  
    transform: scale(1.05);    /* Slightly enlarge on click */
}
.card-icon{
 display: flex;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    width: 30%;
    border: 2px solid white;
    color: black;

    justify-content: center;
    align-items: center;
   
background-color: #d1e6f3;
}
.cardtre h2 {
    margin-bottom: 10px;
    display: flex;
    font-weight: 500;
    
}
.makeflex{
    display: flex;
    justify-content: space-between;
}
.icorep{
    font-size: 3rem;
    display: flex;
    justify-content: left;
  
    margin: 0;
    padding-bottom: 10px;

    border-radius: 5px;


}
.cardtre p {
    font-size: 1.5rem;
    font-weight: bold;
}
/* TemplateReport.css - Add or update these styles */

.status-response {
  
    background-color: #e7f3fe;
 border: 1px solid #78b7ff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.status-response h4 {
    margin-top: 0;
}

.status-response ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
}

.status-response li {
    margin-bottom: 10px; /* Space between items */
    padding: 10px;
    background-color: #fff;
    border: 1px solid #78b7ff;
    border-radius: 5px;
}
.report-container {
    padding: 20px;
 
 

    border-radius: 8px;
   

}



.card {
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
    width: calc(30% - 20px); /* Three cards per row with gap */
}

.card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.card h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333; /* Dark color for the title */
}

.card p {
    font-size: 1.2em;
    color: #555; /* Slightly lighter color for text */
}

.status-response {
   
    padding: 15px;
    /* background-color: #e7f3fe; */
    border: 1px solid #78b7ff;
    border-radius: 8px;
    width: 100%;
}

.error-message {
    margin-top: 10px;
    color: red;
    font-weight: bold;
}
.status-table {
    width: 100%;
    border-collapse: collapse; /* Makes borders collapse into a single border */
    margin-top: 10px;
    border: 1px solid #5ca8ff ;
   
}

.status-table th, .status-table td {
   border: 1px solid #5ca8ff ;
    padding: 10px; /* Padding for table cells */
    text-align: center; /* Align text to the left */
}

.status-table th {

    font-weight: bold; /* Bold text for header */
}

.status-table tr:hover {
    background-color: #f5f5f5; /* Light background on row hover */
}
.status-table th{
    background-color: #78b7ff;
    color: #003168;
}
@media (max-width: 768px) {
    .status-table th, .status-table td {
        font-size: 14px;
        padding: 6px 8px;
        
    }
    .status-response{
       
    }
    .card-container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .containersd{
    display: flex;
    flex-direction: column;
   gap: 10px;
}
}
/* Table container to enable scrolling on mobile */
.status-table-container {
    overflow-x: auto; /* Horizontal scrolling for mobile */
    margin: 0;
    padding: 0;
}

.status-table {
    width: 100%;
    border-collapse: collapse;
}

.status-table th, .status-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: center;
}

/* Style for mobile view */

/* CampaignReportPage.css */
/* TemplateReport.css */
.containersd {
    display: grid;
    gap: 16px;
  
}


.box {
    
       
    justify-content: center;
align-items: center;

}
 .box3{
 display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; 
      padding: 16px;
    background-color:transparent;
    border-radius: 8px;
    border: 1px solid #78b7ff;
        background-color: white;
}
 .box2{
 display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; 
      padding: 16px;
    background-color:transparent;
    border-radius: 8px;
    border: 1px solid #78b7ff;
        background-color: white;
}
.box1{
      padding: 16px;
    background-color:transparent;
    border-radius: 8px;
    border: 1px solid #78b7ff;
    background-color: white;
}
.tabling{
    display: flex;
    gap: 20px;
    height: auto;
}
#fscrds{
    text-align: left;
}
#thsunm{
    background-color: #FF6384;
    color: white;
    font-weight: 600;
    padding: 10px;
}
@media (min-width: 768px) {
    /* Desktop layout */
    .containersd {
        grid-template-columns: 1fr 2fr 1fr;
    }
}

@media (max-width: 767px) {
    /* Mobile layout: each box takes full width in a single column */
    .containersd {
        grid-template-columns: 1fr;
    }
    .tabling{
        display: flex;
flex-direction: column;

max-width: 100%;
    }
    .tabsicar{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
}
.skeleton-card {
    width: 250px;
    height: 150px;
    background: #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    animation: shimmer 1.5s infinite;
}

.skeleton-title {
    width: 60%;
    height: 20px;
    background: #d6d6d6;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-subtitle {
    width: 40%;
    height: 15px;
    background: #d6d6d6;
    border-radius: 4px;
}

@keyframes shimmer {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
