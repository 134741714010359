
.con1 {
   padding: 10px;
    margin-right: 0px;
    margin-top: 0px;
}

.con1.dark {
    /* background-color: #18191b; */
    /* background: linear-gradient(to bottom,  #0694c6 10%,  #0694c6 10%, #000000 50%, #000000 100%); */
    background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
    color: white;
}

.con1.light {
    /* background-color: #ecf0f6; */
    /* #0F3443 + #34E89E */
    /* #d2ffff + #0694c6 */
     /* background: linear-gradient(to bottom, #00d76f 10%, #00d76f 30%, #ecf0f6 50%, #ecf0f6 100%); */
      /* background: linear-gradient(to bottom,  #0694c6 10%,  #0694c6 10%, #ecf0f6 50%, #ecf0f6 100%); */
       background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
   
    color: black;
}
@media (max-width: 768px) {
    .con1 {
   
        padding-left: 20px;
    }
}