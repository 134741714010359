/* Basic styles */
.dashboard {
    margin: 0 auto;
  
  
}

.headcount, .cou, .dashccc {
    margin: 0px !important;
    padding: 0px !important;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row !important;
     border: 1px solid rgb(185, 184, 184);
}

.right-section,
.left-section {
    padding: 10px;
    box-sizing: border-box;
}

.right-section {
    text-align: center;
    flex: 1 1; /* Ensures the right section takes up 45% of the available width */
}

.left-section {
    flex: 1 1 0%; /* Ensures the left section takes up 50% of the available width */
    
}

.dashch {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    text-align: center;
}

/* Filter buttons */
.filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
     border: 1px solid rgb(185, 184, 184);
}

.filter-container button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #cecece;
    color: #ffffff;
    cursor: pointer;
    margin: 2px;
}

.filter-container .active {
    background-color: #71b5fd;
}

/* Cards container */
.dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

/* Card styles */
.card {
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
    padding: 10px;
    box-sizing: border-box;
}

.card h3 {
    font-size: 18px;
    font-weight: bold;
}

.card:hover {
    transform: translateY(-5px); /* Slight lift effect */
    box-shadow: 0 15px 30px rgba(204, 216, 255, 0.966);
    border: 1px solid rgb(111, 111, 245);
}

.card p {
    font-size: 14px;
}

/* Conditional status text colors */
.status-approved {
    color: #009605 !important; /* Green for Approved */
    background-color: #a9ffac;
    border-radius: 5px;
}

.status-pending {
    color: #ce0303 !important; /* Red for Pending */
    background-color: #fa8181;
    border-radius: 5px;
}

.status-text {
    font-weight: 500;
    text-align: center;
    padding: 0px 5px;
}

.left-section {
    padding: 20px;
}

.bottom-section {
    background-color: #007bff;
    padding: 10px;
}

.bottom-section p {
    color: white;
}
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column; /* Stack items vertically */
        gap: 5px; /* Add more space between items */
        align-items: stretch; /* Ensure elements take full width */
    }
}
/* Responsive styles */
@media (min-width: 768px) {
    .card {
        width: 48%; /* Two cards per row on medium screens */
    }
    .card h3 {
    font-size: 14px;
    font-weight: bold;
}



@media (min-width: 1024px) {
    .card {
        width: 30%; /* Three cards per row on larger screens */
    }
}

/* Ensure cards wrap correctly on small screens */
@media (max-width: 576px) {
    .dashboard-cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        /* Full width cards on very small screens */
    }

    .card h3 {
     
    }

    .card p {
        font-size: 0.9rem; /* Adjust paragraph size on smaller screens */
    }
   .filter-container{
    display:flex;
    flex-direction: column;
}
}
}
