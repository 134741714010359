.bar {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 10px;
    padding: 20px;


}
#headert{
    display: flex;
justify-content: space-between;
margin: 10px;

 }
/* .bar.dark {
    background-color: #0093E9;

    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    color: white;
}

.bar.light {
background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
   border: 1px solid rgb(255, 255, 255);
    color: rgb(2, 71, 120);
} */
#advertise{
background: linear-gradient(135deg, #9eee9a, #fabfe2, #88c8f0, #c1d8fc);
margin: 20px;
display: flex;
justify-content: center;
border: 1px solid white;

display: flex;
flex-direction: column;


}
.headingadv {
    font-weight: 600;
    padding-top: 30px;
    font-size: 1.9rem; /* Responsive font size */
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  
}

.search-container {
    position: relative;
    display: inline-block;
    width: 50%;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.search-icon {
    position: absolute;
    top: 40%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #888;
}

.search-input {
    padding: 10px 10px 10px 35px; /* Add left padding to make space for the icon */
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.search-input.dark {
    background-color: rgb(48, 48, 48);
    border: 1px solid #313131;
    color: white;
}

.search-input.light {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: black;
}

.search-input:focus {
    outline: none;
    border-color: #25D366;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .bar {
        height: auto;
        padding: 15px;
    }

    .headingadv {
        font-size: 1rem; /* Smaller font size for tablet view */
        padding-top: 20px;
    }

    .search-container {
        width: 70%;
        margin-top:45px;
       
    }

    .search-input {
        font-size: 14px; /* Smaller input font size for tablet view */
    }
    #advertise{
        margin-top: 10px;
        
    }
}

@media (max-width: 480px) {
    .bar {
        height: auto;
        padding: 10px;
    }

    .heading {
        font-size: 1.2rem; /* Smaller font size for mobile view */
        padding-top: 15px;
    }

    .search-container {
        width: 90%;
    
       
    }

    .search-input {
        font-size: 14px; /* Smaller input font size for mobile view */
        padding: 8px 8px 8px 30px;
        
    }
}
