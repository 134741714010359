/* Chatbox.css */
.chatboxmod {
    background-color: #e5e5ea;
    border-radius: 0px 10px 10px 10px;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.chat-header-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 8px;
}
.chadiv1{
   height: auto;
  border-radius: 5px;
    padding: 15px 50px 10px 15px;
    background: url(../../assets/bgd.jpg);
   
}
.chat-headermod, .chat-bodymod, .chat-footermod {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.chat-buttons12mod {
    text-align: right;
}

.chat-button1mod {

    color: rgb(3, 3, 3);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin: 10px 0px;
}

.chat-button1mod:hover {
    background-color: #1ebe59;
}
