.header-container {
    margin-bottom: 16px;
}

.text11 {
    font-size: 16px;
    margin-bottom: 8px;
}

.optional {
    font-size: 12px;
    color: gray;
}

.header-section {
    display: flex;
    flex-direction: column;
    position: relative;
}

.header-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.toolbar-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.toolbar-button {
    padding: 6px 8px;
    margin: 0;
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.toolbar-button:hover {
    background-color: #e0e0e0;
}

.add-variable-button {
    padding: 6px 8px;
    margin-left: 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-variable-button:disabled {
    background-color: #9e9e9e;
}

.variable-input-group {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.variable-label {
    font-size: 14px;
    font-weight: bold;
}

.variable-input {
    padding: 6px;
    font-size: 14px;
    width: 100%;
    border-radius: 4px;
}

.remove-variable-button {
    padding: 4px 6px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.remove-variable-button:hover {
    background-color: #c9302c;
}

.warning-message {
    color: #d9534f;
    font-size: 12px;
    margin-top: 4px;
}
.custom-dropdown {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.dropdown-options {
    border: 1px solid #ccc;
    position: absolute;
    background: white;
    z-index: 1000;
    width: 100%;
}

.dropdown-options div {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-options div:hover {
    background: #f0f0f0;
}

.dropdown-icon {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000; /* Triangle pointing down */
    margin-left: 10px;
    transition: transform 0.3s;
}

.dropdown-icon.open {
    transform: rotate(180deg); /* Rotate to point up */
}
