.dashboard-cardsdas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   gap:20px;
    margin-top: 0px;
  
}

.carddas {
    flex: 1 1;  /* This ensures the cards are responsive, flexing between 300px and their maximum width */
    max-width: 400px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
         
  
}

.card-contentdas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
      gap: 10px;
           font-family:poppins;
          
            border-radius: 5px;
            padding: 5px;
          
}

.card-textdas {
    flex: 1;
}

.allcharts {
    margin: 20px;
    overflow-x: hidden;
 
}

.card-titledas {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  
}

.card-valuedas {
    font-size: 44px;
    font-weight: 600;
    margin: 5px 0;
}

.card-trenddas {
    font-size: 14px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
}

.hisbo {
    /* Custom styles for History box */
     border: 1px solid rgb(185, 184, 184);
     padding-top: 5px;
     margin-bottom: 20px;
}

.card-graphdas {
    flex: 1;
    position: relative;
    height: 120px;
    max-width: 120px;
    margin-left: 20px;
}

.cardtotal {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 16px;
}

@media (max-width: 768px) {
    /* Make card full width for mobile */
    .carddas {
        flex: 1 1 100%;
        max-width: none;
    }

    /* Stack card content vertically on smaller screens */
    .card-contentdas {
      
        align-items: flex-start;
    }

    /* Adjust graph to full width of the card */
    .card-graphdas {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    /* Stack cards in column layout for smaller screens */
    .cardtotal {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .card-titledas {
        font-size: 16px;
    }

    .card-valuedas {
        font-size: 60px;
    }

    .card-trenddas {
        font-size: 12px;
    }
}
