.dashboard-charts-container {
    display: flex;
    gap: 20px;
    justify-content: center;
 
  
}

.chart {
    width: 45%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(185, 184, 184);
}

.chart h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}
.fdb{
    background-color: rgb(255, 255, 255) !important;
    
}
.fdb h3{

}

@media (max-width: 480px){
.dashboard-charts-container{
    flex-direction: column;
    width: 100%;
}
.chart{
    width: 100% !important;
}
}