/* TwoFlexboxComponent.css */


.flexbox-container {
  position: relative;
  display: flex;
  gap: 20px; 
padding: 20px;
width: 100%;
overflow-x: hidden;
font-size: 1rem;

}

.flexbox-item {
  flex: 1;
  padding: 30px;
 
  display: flex;
  flex-direction: column; 
  gap: 10px; 
  width:50%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.flexbox-item1 {

padding: 10px;
 display: flex;
 flex-direction: column;
  background-color: white;
width: auto;
border-radius: 5px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  

}
/* SelectTemplate.css */
.buttons button {

    align-items: center;
    gap: 8px; /* Space between icon and text */
    padding: 10px 20px;
    border: 1px solid rgb(177, 177, 177);
    cursor: pointer;
    font-size: 14px;
  
}

.icon {
    font-size: 16px; /* Adjust the icon size */
    color: inherit; /* Match the button's text color */
}

.buttons button{
  width: 100%;


  
}
.buttons button.active {
  background-color: #a3a1a1; /* Highlight the active button */
  border: 1px solid #ccc;
}
.buttons{
  display: flex;
    

}
/* SelectTemplate.css */
/* .flexbox-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.flexbox-item {
  flex: 1;
  background: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
} */

.buttons {
  display: flex;

  margin-top: 10px;
}

.buttons button {
  padding: 10px 20px;

  cursor: pointer;
  transition: background 0.3s;


  color: rgb(27, 27, 27);
}

.buttons button:hover {
  background: #838485;
}

.dropdown-box-seltemp {
  margin-top: 10px;
  padding: 0px;
display: flex;
flex-direction: column;
justify-content: space-around;
justify-items: left;
text-align: left;
  border-radius: 8px;
  background: white;
  width: 100%;
}
.inptradio{
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.dropdown-box-seltemp h4 {
  margin-bottom: 10px;
}

.dropdown-box-seltemp ul {
  list-style: none;
  padding: 0;
}

.dropdown-box-seltemp li {
  padding: 10px;
  background: #f0f0f0;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background 0.3s;
  text-decoration: left;
}

.dropdown-box-seltemp li:hover {
  background: #e0e0e0;
}
.radio-option1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0 0 20px;
  justify-items: left;

}
.radio-option1 input[type="radio"] {
  transform: scale(2); 
  margin-right: 20px;
  margin-top: 4px;
  cursor: pointer;

}

.radio-option1 input {
  margin-right: 10px;
}
.option-content {
  display: flex;
  flex-direction: column;
}

.option-content b {
  margin-bottom: 4px;
}

.option-content p {
  margin: 0;
  color: #666;
}
.radio-option1:hover{
  background-color: rgb(226, 229, 252);
  border-radius: 10px;
  padding-bottom: 10px;
}
.category-image {
    width: 100%;
    height: auto;
    max-width: 300px; /* Adjust as needed */
}


.start-button {
    background-color: #b3b5b3; /* Customize button color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.end-button {
    background-color: #00417d; /* Customize button color */
    color: white;
  
  border-radius: 5px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
/* SelectTemplate.css */
#buttons-con{
  padding: 5px;
  width: 98%;
   margin: 10px;
   margin-left: 20PX;

}

.buttons-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
 width: 100%;
    background-color: white;
    border-radius: 5px;

 
}

.start-button, .end-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}



/* Additional styles for dropdowns, images, etc. */
.dropdown-box {
    background-color: inherit; /* Adjust according to theme */
    padding: 10px;
   
}

.bg{
 background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
    height: 100vh;
    padding-top: 20px;
}
.category-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slide-in {
  transform: translateX(0);
  opacity: 1;
}

.slide-out {
  transform: translateX(50px); /* Slide out to the right */
  opacity: 0;
}
.flhead{
 background:none;

 color: #00417d;
 padding-left: 20px;
}

.step-selector {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.round-button {
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
    position: relative;
    font-size: 16px;
}

.round-button.active {
    border-color: #25D366; /* Active button color */
    color: #25D366;
}

.round-button.completed {
    background-color: #25D366; /* Completed button background color */
    color: #fff;
}

.round-button .check-icon {
    position: absolute;
    font-size: 20px;
}

.step-selector span {
    margin: 0 15px;
}

.step-selector .disabled {
    color: #ddd;
    cursor: not-allowed;
}

@media (max-width: 768px) {
  .flexbox-container {
    flex-direction: column; /* Stack items on smaller screens */
 font-size: 0.8rem;
 justify-content: center;
 align-items: center;
 
  }

  .flexbox-item,
  .flexbox-item1 {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Maintain bottom margin */
    
   
  }
  .buttons-container{
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 20px;
    
  }
  .buttons{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.buttons button {

    align-items: center;
    gap: 10px; /* Space between icon and text */
    padding: 10px 10px;
    border: 1px solid rgb(177, 177, 177);
    cursor: pointer;
    font-size: 14px;
  
}
}

@media (max-width: 480px) {
  .start-button, .end-button {
  
  
    display: flex;
   justify-content: space-around;
    align-items: center;
  }
  .flhead h4{
    font-size: 1rem;
  }

  .flexbox-item1 {

       width: auto;
   
  }
   .flexbox-item{
    width: 100%;

   }
  .buttons-container {
    
  margin: 10px;
 padding: 10px;
    width: 95%;
}
}