/* src/components/ChatPreview/ChatPreview.css */
/* .chat-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
 
    background-color: #e31c1c;
    max-width: 400px; 
  background-image: url('../assets/bgd.jpg');
}

.chat-bubble {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 15px;
    background-color: #fafafa;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
} */

/* src/components/ChatPreview/ChatPreview.css */
.chat-preview {
    display: flex;
    flex-direction: column;
    justify-content: left;
  


    padding: 20px;
  
height:auto;
    background-color: rgb(255, 255, 255);

   
} 


.chat-bubble {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 80%;
    padding: 15px;
    background-color: #e6f7ff;
    border-radius: 0px 10px 0px 0px ;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    position: relative;
  /* margin-bottom: 5px; */
    max-width: 300px; /* Adjust the width according to your design */
    word-wrap: break-word; /* Ensures words wrap inside the chat bubble */
    overflow: hidden; /* Prevents content from overflowing */
    height: auto;
    padding: 20px;
}

.chat-header,
.chat-body,
.chat-footer {
    margin-bottom: 10px;
}

.bold-text {
    font-weight: bold;
}

.chat-header p,
.chat-body p,
.chat-footer p {
    margin: 0;
}

.chat-time {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 12px;
    color: #555;
}

.chat-buttons-pr {
    display: flex;
    flex-direction: column;
    width: 100%; /* Matches the width of the chat bubble */
}

.chat-button12-pr {
    padding: 10px 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
     background-color: #e6f7ff;
    border: none;

    /* border-radius: 5px; */
    color: rgba(2, 100, 247, 0.993);
    cursor: pointer;
    font-size: 14px;
    width: 100%; /* Full width of the chat box */
    box-sizing: border-box;
    text-align: center;
    /* border-bottom: 1px solid rgb(189, 189, 189); */
    border-top:1px solid rgb(163, 163, 163) ;
   
}

/* .chat-button12-pr:hover {
    background-color: #128c7e;
} */
.chat-header p,
.chat-body p,
.chat-footer p {
    margin: 0;
    word-break: break-word; /* Breaks long words to fit within the container */
    overflow-wrap: break-word; /* Ensures that words wrap properly */
}
.chat-buttons-pr{
    
    color: rgb(0, 0, 0);
    border: none;

    border-radius: 4px;
    cursor: pointer;
    width: 80%;
    display: flex;
    justify-items: center;
}

.image-preview-container {
    margin: 10px 0; /* Add some margin above and below the image */
}

.image-preview {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: adds rounded corners */
}
.header-image{
    width: 100%;
    height: auto;
}