/* Dark Theme Styles */
.dark .card_temp {
    background-color: #28292b;
    border: 1px solid rgb(82, 82, 82);
    color: white;
           
  
}



.template-table .badge-closed {
    background-color: rgb(255, 126, 139);
    color: #bd0101 !important; /* Important added */
}

.template-table .badge-created {
    background-color: rgb(134, 240, 159);
    color: #03aa4e !important;
}



.template-table {
    width: 100%;
   padding: 20px;
    background-color: white;
    border: none;
}

.template-table th, .template-table td {
border-bottom: 1px solid rgb(206, 206, 206);
    padding: 20px;
  
}
.template-table td {

    color: #525151;
}
.template-table tr:hover {
  
    cursor: pointer;
}
.template-table tr{

}
.template-table th tr{
 border-radius: 10px;

font-size: 1.2rem;
    color: rgb(255, 255, 255);
    background-color: #1877F2;
}
.create-template-button {
  margin: 20px;
    padding: 10px 20px;
    background-color: #00417d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-template-button:hover {
    background-color: #45a049;
}
.modal-backdroptl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.349);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-contenttl {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.267);
    position: relative;
    min-width: 300px;
}

.modal-bodytl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-spinnertl {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}
.tabheadert{
      background-color: #1877F2;
      color: white !important;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.checkmark-containertl {
    width: 40px;
    height: 40px;
    position: relative;
    margin-bottom: 20px;
}

.checkmarktl {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #00417d;
    position: relative;
}

.checkmarktl::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 20px;
    border: solid white;
    border-width: 0 4px 4px 0;
    top: 8px;
    left: 10px;
    transform: rotate(45deg);
}

h2 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.close-btntl {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #00417d;
    color: #fff;
 
}
.tabbox {
    overflow-y: scroll !important;
  padding: 0px !important;
    border: 1px solid #ccc;
    border-radius: 8px;

 
   

    height: 750px; /* Fixed height */
    overflow-y: auto; /* Add auto for better handling */
    scrollbar-width: thin; /* Firefox fallback */
    scrollbar-color: #1ebe59 #f1f1f1; /* Firefox thumb and track */
    /* Ensure padding/margins don't affect scrolling */
    padding: 0;
    margin: 0;
}

/* WebKit Scrollbar */

.template-table tbody tr {
    cursor: pointer;
}
.template-table tbody tr:hover {
    background-color: #f1f1f1;
}
.reccampsu{
    padding: 10px;
}
.sticky-header {
    position: sticky;
    top: 0;
    background: #f9f9f9;
    z-index: 100;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}


.scroll-container {
    height: 750px; /* Ensure fixed height */
    overflow-y: auto;
    position: relative;
    border: 1px solid #ddd;
}

.template-table {
    width: 100%;
    border-collapse: collapse;
}

.template-table th, .template-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.template-table  {
     height: 800px; 
}
.badge-pending {
    background-color: #ffe9a7; /* Yellow */
    color: #dbbb01 !important;
}
.status-column {
    width: 4%; /* Equal width for all columns */
    text-align: center; /* Center-align text */
    padding: 8px; /* Add padding for spacing */
    box-sizing: border-box; /* Include padding in the width */
    border: 1px solid #ddd; /* Optional: Add borders for clarity */
   text-align: center !important;
}