/* General Styles */

.albody{
 background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
padding: 20px;

width: 100%;
font-size: 16px;
}
.containernext {
    display: flex;
    /* Default for mobile: stack items vertically */
    gap: 10px; /* Space between boxes */
    padding: 10px;
top: 40px;
}

/* Left and Right Side Styling */
.left-side{
    width: 60%;
   
}
.right-side{
    width: 100%;
    max-width: 500px;
    float: 1;
   
}
.sticky-preview {
  position: sticky;
  top: 60px; /* Adjust for desired spacing from the top of viewport */
  align-self: flex-start;
  width: 100%;
}
/* Box Styling */
.box {
    background-color: #ffffff;
 padding: 30px 20px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 30px;
    
}
.headig{
    
    
display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
 

  
  
}
.box-container1 {
  display: flex;
  flex-direction: column;
margin: 20px;
  width: 100%;
  gap: 20px; /* Space between the boxes */
}

.box-hgsh{
    background-color: #ffffff;
padding: 15px 20px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 30px;
}
.box-hgsh p{
    margin: 0;
   
}


















/* Media Queries for larger screens */
@media (max-width: 768px) {
   .containernext{
    display: flex;
    flex-direction: column;
    
   }
.albody{
 
    padding: 10px;
    font-size: 12px;
}
 .albody h4{
    font-size: 1.2rem;
    text-align: left;
 }
    .box {
        margin-bottom: 10px;
    }
    .right-side{
        width: 100%;
    }
    .left-side{
        width: 100%;
    }
    .box-hgsh{
        margin-bottom: 10px;
    }
}

/* Even larger screens */
@media (min-width: 1024px) {
    .containernext {
  
       
        gap: 20px;
    }

    .box {
     
    }
}

/* Loader */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}



button:disabled {
    background-color: gray;
}
/* Center the modal overlay */
.modal-overlaysus {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

/* Style the modal content */
.success-modalsus {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px; /* Adjust width as needed */
    max-width: 90%; /* Ensure it fits on smaller screens */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}
.mdh2ta{
    color: rgb(3, 170, 3);
}