.history-container {
  

    border-radius: 8px;

}

.history-table {
    width: 100%;
    border-collapse: collapse;
}

.history-table th, .history-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.history-table th {
    background-color: #007bff;
    color: white;
}

.status.completed {
    color: #4CAF50; /* Green for completed */
}

.status.pending {
    color: #FF4D4D; /* Red for pending */
}
