/* Centering the container */
.construction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full screen height */
    text-align: center; /* Center align text */
    background-color: transparent; /* Light background for better visibility */
    padding: 20px; /* Ensure proper spacing */
    box-sizing: border-box;
}

/* Styling the image */
.construction-image {
    max-width: 500px; /* Increased maximum width */
    width: 100%; /* Ensure it remains responsive */
    margin-bottom: 20px; /* Spacing between image and heading */
    transition: transform 0.3s ease-in-out; /* Add smooth scaling effect on hover */
}

.construction-image:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Heading style */
.construction-heading {
    font-size: 2.5rem; /* Large text size */
    color: #1f44b4; /* Blue color to match a professional theme */
    margin-bottom: 10px; /* Space below heading */
    font-family: 'poppins', sans-serif; /* Clean, modern font */
}

/* Message style */
.construction-message {
    font-size: 1.2rem; /* Slightly smaller than the heading */
    color: #333; /* Dark gray for readability */
    line-height: 1.6; /* Improve readability with spacing */
    max-width: 600px; /* Limit message width */
    margin: 0 auto; /* Center within the container */
    font-family: 'Arial', sans-serif;
}
