.breadcrumbs ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    font-size: 16px;
    align-items: center;
}


.breadcrumbs li {
    display: inline;
}

.breadcrumbs a {
    color: #007bff;
    text-decoration: none;
}

.breadcrumbs a:hover {
    text-decoration: underline;
}

.breadcrumbs span {
    color: #6c757d;
    pointer-events: none;
    text-transform: capitalize; /* Capitalize breadcrumb labels */
}
