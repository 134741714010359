/* ModifyTemplate.css */
.modify-template {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
   width: 100%;
   
}
.modify-template-container{
    display: flex;
    flex-direction: column;

 width: 700px;

}
.template-inputs {
  
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.modify-template-container .box{
    text-align: left;
}

.inval {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    background-color: #25d366;
    color: white;
    border: none;
    padding: 15px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  
    width: 100%;
    margin-bottom: 20px;
}
.hh44{
    background-color: black;
    color: white;
    padding: 20px;
   
}
.submit-button:hover {
    background-color: #1ebe59;
}

.chat-previewmod {
 height:fit-content;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 40px;
    margin-top: 0px;
    
    margin-right: 50px;
    max-width: 500px;
    width: auto;
}

.chatbox {
    background-color: #e5e5ea;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
}

.chat-header,
.chat-body,
.chat-footer {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.chat-buttons {
    text-align: right;
}

.chat-button {
    background-color: #25d366;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.chat-button:hover {
    background-color: #1ebe59;
}
.status-section{
    padding: 20px;
    color: black;
    background-color: white;
    width: 100%;
    margin-top: 20px;
    text-align: left;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.status{
    flex-direction: row;
    justify-content: space-between;
}
/* Add this to your CSS file (e.g., ModifyTemplate.css) */
.modal-overlaymo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-contentmo {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-contentmo button {
    margin-top: 10px;
}
.checkmark-circle {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes it circular */
    background-color: green; /* Green background */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px; /* Center the circle and add some space below */
}

.checkmark {
    color: white; /* White checkmark color */
    font-size: 24px; /* Adjust size of the checkmark */
}
.button-container {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    margin-top: 15px; /* Adds some space above the button */
}

.button-container button {
    padding: 10px 40px; /* Adjust button padding as needed */
    background-color: green; /* Optional: Make the button consistent with the modal */
    color: white; /* White text for the button */
    border: none; /* Removes default border */
    border-radius: 5px; /* Optional: Rounded corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
}



.sendinput{
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 5px;
    padding: 10px;
    
}
@media (max-width: 768px) {
    .modify-template {
        padding: 10px; /* Adjust padding on smaller screens */
        display: flex;
        flex-direction: column;
        margin-left: 0;
    }

   .chat-previewmod {
 height:fit-content;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    margin: 40px;
    
    width: 100%;
}

.submit-button{
    width: 100%;

    margin: 10px;
  
 
 
}
.button-container{
      display: flex;
    justify-content: flex-start;
    text-align: center;
}
.modify-template-container{

 
width: 100%;
}
.chat-previewmod {
 margin: 0px;
    width: 100%;
}
}

.loading-bar {
    width: 100%;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 10px;
    position: relative;
    overflow: hidden;
}

.loading-progress {
    width: 0;
    height: 100%;
    background-color: #4caf50;
    animation: loading 2s infinite;
}

@keyframes loading {
    0% { width: 0; }
    50% { width: 50%; }
    100% { width: 100%; }
}
