.auth-template-container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  width: 900px;
  margin: 40px auto;

}
.firsthead{
    padding: 0px;
       box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
     width: 100%;
     margin-bottom: 20px;
}
.auth-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.auth-template-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
}

.auth-delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-delete-button:hover {
  background-color: #ff2d2d;
}

.auth-template-section {
  margin-bottom: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.auth-input-group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.auth-input,
.auth-select {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;
  transition: border-color 0.3s ease;
}

.auth-input:focus,
.auth-select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.auth-radio-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-radio-group label {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.auth-radio-group label:hover {
  background-color: #f1f1f1;
  border-color: #bbb;
}

.auth-radio-group label input {
  margin-right: 10px;
}

.auth-radio-group label p {
  margin: 5px 0 0;
  color: #777;
  font-size: 0.9rem;
}

.auth-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
.auth-buttons-section{
 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
.auth-discard-button {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-discard-button:hover {
  background-color: #333;
  color: white;
}

.auth-submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-submit-button:hover {
  background-color: #0056b3;
}

.auth-add-app-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-add-app-button:hover {
  background-color: #0056b3;
}

.auth-buttons-section,
.auth-validity-period-section {
  margin-bottom: 30px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.auth-button-options {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.auth-auto-fill-section,
.auth-copy-code-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.auth-input {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-top: 8px;
  transition: border-color 0.3s ease;
}

.auth-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.auth-auto-fill-section small,
.auth-copy-code-section small {
  margin-top: 5px;
  color: #777;
  font-size: 0.85rem;
}

.auth-toggle-custom-period {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.auth-toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.auth-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.auth-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  transition: background-color 0.4s;
  border-radius: 24px;
}

.auth-toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: transform 0.4s;
  border-radius: 50%;
}

.auth-toggle-switch input:checked + .auth-toggle-slider {
  background-color: #007bff;
}

.auth-toggle-switch input:checked + .auth-toggle-slider:before {
  transform: translateX(21px);
}

.auth-validity-period-input {
  margin-top: 15px;
}

.auth-select-period {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.auth-select-period:focus {
  border-color: #007bff;
  outline: none;
}
