/* Custom table header and row styles */
th, td {
padding: 10px !important;
    vertical-align: middle;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
}
th{
    background-color: #1877F2 !important;
    padding: 10px !important;
    color: white !important;
    
}
/* Custom Hover Effects for Table Rows */
.table tbody tr:hover {
   /* Light grey background on hover */

    background-color: #0056b3 !important;
}

/* Styling for 'Load More' button */
.btn-primary {
    background-color: #007bff;  /* Default Bootstrap primary color */
    border-color: #007bff;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 10%;
}

.btn-primary:hover {
    background-color: #0056b3 !important; /* Darker shade on hover */
    border-color: #0056b3 !;
    transform: scale(1.05);  /* Slight zoom effect on hover */
}

.btn-primary:focus, .btn-primary:active {
    box-shadow: none;  /* Remove box shadow when clicked or focused */
}

/* Style for message rows with different colors based on message type */
.table td.message-type {
   
    
}

/* Styles for error message */
.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/* Custom Spinner style for Loading */
.spinner-border {
    width: 3rem;
    height: 3rem;
    border-width: 0.25rem;
    color: #007bff;
}

/* Styling for empty message state */
.text-center {
    font-size: 1.2rem;
    color: #999;
}
.containers{
    padding: 20px;
    margin-top: 0px !important;

}