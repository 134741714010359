@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');


.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
   background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
    color: #1877F2;
    width: 100%;
    z-index: 1000;
    padding-right: 40px;
    height: 70px;
}

.navbar.light {
    --navbar-bg: #0694c6;

}

.navbar.dark {
    --navbar-bg:#0694c6;
    
}

.navbar-left {
    display: flex;
    align-items: center;
    
}

.navbar-logo {
    width: 40px;
    height: 40px;
   
    margin-left: 5px;
}

.navbar-title {
    font-size: 2.1rem;
    font-family: 'Lexend Deca', sans-serif;
      font-weight: bold;
      color: #1877F2;
 
    
}

.toggle-container {
    display: flex;
    align-items: center;
    padding-right: 40px;
}

.custom-toggle {
    display: none;
}

.custom-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.custom-label:after {
    content: '';
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.custom-toggle:checked + .custom-label {
    background: #25D366;
}

.custom-toggle:checked + .custom-label:after {
    left: calc(100% - 2.5px);
    transform: translateX(-100%);
}

.theme-label {
    font-size: 12px;
    color: var(--navbar-text);
    margin-left: 10px;
}

.navbar-menu {
    display: flex;
    margin-left: auto;
    font-family: Helvetica;
    font-family: 'Poppins', sans-serif;
}

.menu-item {
    margin: 0 15px;
       /* color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)); */
    
    text-decoration: none;
    font-weight: bold;
}

.menu-item:hover {
    color: #04af1b;

    text-decoration: none;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        padding: 10px;
        height: auto;
    }
    
    .navbar-left {
        flex: 1;
    }
    
    .navbar-title {
        font-size: 18px;
        padding-top: 5px;
    
    }
    
    .navbar-logo {
        margin-left: 10px;
    }
    
    .navbar-menu {
        display: none;
    }
    
    .toggle-container {
        flex: none;
        margin-left: auto;
        padding-right: 0px;
    }
    
    .custom-label {
        width: 40px;
        height: 20px;
    }
    
    .custom-label:after {
        width: 15px;
        height: 15px;
    }
    
    .theme-label {
        font-size: 10px;
        margin-left: 5px;
    }
    
}
.top-bar{
    display: none;
}
@media (max-width: 768px){
 
    .top-bar {
        display: flex;
        justify-content: space-around;
      justify-items: baseline;
        align-items:baseline;
        width: 100%;
margin: 0px !important;
        padding: 10px 0px 0px 0px;
        text-align: center;
 font-family: monospace;
        font-size: 12px;
    }
    .loginbn{
        background-color: rgb(241, 182, 182);
        border: none;
        border-radius: 10px;


    }
}