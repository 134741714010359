.chatboxmod {
    background-color: #e5e5ea;
    border-radius: 0px 10px 10px 10px;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left !important;
}

.chadiv1 {
    height: auto;
    border-radius: 5px;
    padding: 15px 50px 10px 15px;
    background: url(../../assets/bgd.jpg);
}
.chadiv{
margin-bottom: 10px;
 

}
.status {
    font-weight: bold; /* Optional for better visibility */
}
.status.APPROVED {
    color: green;
    background-color: #8cfab4;
    border-radius:5px;
    padding: 5px;
}

.status.PENDING {
    color: red;
      background-color: #f7bebe;
    border-radius: 5px;
    padding: 5px;
}

.status.FAILED {
    color: yellow;
}

.status.unknown {
    color: black;
}
.chat-headermod, .chat-bodymod, .chat-footermod {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.chat-buttons12mod {
    text-align: right;
}

.chat-button1mod {
    color: rgb(3, 3, 3);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin: 10px 0px;
}


.template-card {
    margin: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
   
     overflow-wrap: break-word;
}
.template-name h3 {
    margin: 0 0 10px;
    font-size: 18px;
    color: #077531;
    font-weight: 500;

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis (...) for long text */
}

.template-name h3:hover::after {
    content: attr(data-title); /* Display full title from attribute */
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: normal;
    max-width: 200px;
    z-index: 1000;
    transform: translateY(-50%);
}

.typtwocat{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.catmautau{
     font-size: 18px;
     font-weight: bold;
     color: #5e5e5e !important;
}
.template-name p {
    margin: 0;
    font-size: 14px;
    color: #555;
  
     
}
.custom-tooltip {
    position: absolute;
    display: none;
    background-color: rgba(0, 0, 0, 0.75); /* Dark background for readability */
    color: #fff; /* White text */
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none; /* Ensure it doesn't interfere with mouse events */
    z-index: 1000; /* Ensure it appears on top of other elements */
    max-width: 200px;
    word-wrap: break-word; /* Wrap long text */
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 1px; /* Width of the scrollbar */
    height: 5px; /* Height of the scrollbar (for horizontal scroll) */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for the scroll track */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #1ebe59; /* Green color for the thumb */
    border-radius: 10px;
    transition: background 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: #17a34d; /* Darker green on hover */
}

::-webkit-scrollbar-button {
    background-color: transparent;

}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
.chat-headermod {

}

.header-icon {
    font-size: 2rem; /* Professional size for icons */
    color: #555; /* Neutral color for icons */
    align-items: center;
    text-align: center;
    
}