.next-component-container {
 
    text-align: center;
    background: linear-gradient(to bottom,  #0694c6 10%,  #0694c6 10%, #e4ebf6 50%, #e4effe 100%);
    height: 100;
   position: relative;

 margin-right: 300px;
 padding-right: 100px;
    
}
.p2{
color: white;
text-align: left;
}
.step-selector1 {
    margin: 20px 0 o 200px;
padding: 200px 0 0 300px
}

.round-button1 {
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 50%;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.round-button1.completed {
    background-color: green;
    color: white;
    border: 2px solid green;
}

.round-button1.completed .check-icon {
    color: white;
}

.round-button1.active {
    background-color: blue;
    color: white;
    border: 2px solid blue;
}

.round-button1.disabled {
    background-color: #f0f0f0;
    color: #888;
    border: 2px solid #ddd;
    cursor: not-allowed;
}

.check-icon {
    position: absolute;
    left: 10px;
}

.back-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.box-container1 {
  display: flex;
  max-width: 600px;
  width: 100%;
  gap: 20px; /* Space between the boxes */
}

.box {
  flex: 1; /* Allows both boxes to take equal space */

 
  border-radius: 8px;

}

.left-box {
  background: transparent;
  max-width: 800px;
  width: 100%;
}

.right-box {
  background: transparent;
   max-width: 400px;
  width: 100%;
}
.firsthead{
    text-align: left;
    background-color: white;
display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 600px;
   padding: 0px 40px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  background-color: #036715; /* Green background */
  border-radius: 20%; /* Make it circular */
}

.icon1 {
  color: white; /* Icon color */
  font-size: 20px; /* Adjust icon size */
}

.text-container {
  display: flex;
  flex-direction: column; /* Adjust to row if needed */
  margin-top: 14px;
}

.hhh2 {
  margin: 0;
  font-size: 18px; /* Adjust font size */
  font-weight: bold;
}

.tempname{
    text-align:left;
    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px 40px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input fields */
  
}

.input-label {
  display: none; /* Hides the label but can be shown as needed */
}

.template-input-field {
  flex: 7; /* 70% width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.language-select {
  flex: 3; /* 30% width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
}
.text11{
   margin: 0;
   padding: 0;
   font-size: 1rem;
   font-weight: 500;
   margin-bottom: 0;
}


.template-container {
 
  background-color: #f9f9f9;
  max-width: 100%;
  margin: 0 auto;
 
}

.header-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.header-dropdown {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  background-color: white;
  width: 100%;
}

.header-input {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.file-name {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
}

.body-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.body-textarea {
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px;
  font-size: 14px;
}

.toolbar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.char-count {
  font-size: 12px;
  color: #555;
  text-align: left;
}

.toolbar {
  display: flex;
  gap: 8px;
}
.bieavi{
 display: flex;
 flex-direction: row;

   justify-content: space-between;
}
.toolbar-button {
  padding: 8px;
  border: none;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.toolbar-button:hover {
  background-color: #ddd;
}

.txt1{
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-weight: 500;
}

.footer-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}
.hbf{
    text-align:left;
    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px 40px;
}
.btttns{
 text-align:left;
    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.dropdown-label {
  margin-bottom: 5px;
  
}

.dropdown {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 200px;
}

.additional-fields {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.input-text {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.buttons-container12 {

    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
}

.start-button {
    background-color: #b3b5b3; /* Customize button color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.end-button {
    background-color: #00417d; /* Customize button color */
    color: white;
  
  
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
/* SelectTemplate.css */



.buttons-container12 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.start-button, .end-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}


.toolbar {
  display: flex;
  gap: 8px;
}

.toolbar-button {
  display: flex;
  align-items: center;
  padding: 6px;
  background: #f1f1f1;
  border: none;
  cursor: pointer;
 
}

.variables-container {
  margin-top: 10px;
}

.variable-input {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.variable-input span {
  margin-right: 10px;
  font-weight: bold;
}

.variable-input input {
  flex: 1;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.delete-variable-button{
    color: red;
    border: none;
    background-color: white;
    
}
.header-section {
    display: flex;
    flex-direction: column;
}

.header-dropdown {
    margin-bottom: 10px;
}

.header-input-container {
    display: flex;
    align-items: center;
}

.header-input {
    flex: 1;
    margin-right: 10px;
}

.variable-button {
    padding: 5px 10px;
}

.char-count {
    margin-top: 10px;
}
.previwetemp{
  background-color: white;
   background-color: white;

  border-radius: 5px;
  text-align: left;
}
.prebox1{
  padding: 0px;
}
.immg{
  width:100px; /*width of your image*/
   height:100px; /*height of your image*/
   
   margin:0; /* If you want no margin */
   padding:0;
}
/* Styling the button */
.review-button {
  background-color: #00417d; /* WhatsApp green color */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Hover effect: scale and shadow */
.review-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  background-color: #00417d;
}

/* Click effect: shrink and shadow change */
.review-button:active {
  transform: scale(0.95);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Ripple effect on click */
.review-button::after {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transform: scale(0);
  transition: transform 0.5s ease-out;
  top: 50%;
  left: 50%;
  pointer-events: none;
}

/* Show ripple effect on active state */
.review-button:active::after {
  transform: scale(2);
  transition: transform 0.2s ease-out;
  opacity: 0;
}
.prebox1 h3{
  background-color: #dff4f7;
  padding: 0;
  margin: 0;
  padding:10px ;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .bieavi {
        flex-direction: column;
        align-items: center;
    }

    .toolbar {
        flex-direction: row;
        justify-content: right;
        gap: 6px;
        width: 100%;
          font-size: 7px;
    }

    .toolbar-button {
      
        margin: 0;
        padding: 0;
        height: 30px;
       
    }
    .toolbar-button span{
      display: none;
    }
}