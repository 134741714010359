.chart-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
   margin: 20px 0;
  gap: 10px !important;
}

.line-chart {
    flex: 0 1 80%;
    height: 300px;
    margin-right: 10px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100% !important;
     border: 1px solid rgb(185, 184, 184);
}

.doughnut-chart {
    flex: 0 1 20%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
     border: 1px solid rgb(185, 184, 184);
     width: 100% !important;
}

@media (max-width: 768px) {
    .chart-container {
        flex-direction: column;
    }

    .line-chart {
        flex: 1 1 100%;
        height: 250px;
        margin-right: 0;
    }

    .doughnut-chart {
        flex: 1 1 100%;
        height: 150px;
        margin-top: 20px;
    }
}
