.hbf{
   
   
}

.button-section {

    border-radius: 8px;

    margin: 0 auto;
   

}

.dropdown-container {
    margin-bottom: 10px;
   
}

.dropdown-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
    color: #333;
}

.dropdown {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.additional-fields {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.field-container {
    display: flex;
    flex-direction: row;
}

.input-text-b {

    border: 1px solid #ccc;
    border-radius: 5px;
    border: 1px solid rgb(138, 137, 137);
 height: 40px;
 width: 30%;
 
}

.button-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.btn-primary {

    background-color: #4CAF50;
    color: white;
    padding: 9px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}
.btn-danger {

    background-color: #bb0a04;
    color: white;
    padding: 9px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}
.btn-primary:hover {
    background-color: #45a049;
}

.btn-remove {
    background-color: #ff4d4d;
    color: white;
    padding: 10px 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-top:-10px;
    font-weight:900;
}

.btn-remove:hover {
    background-color: #e60000;
}

.added-buttons {
    margin-top: 20px;
}

.button-preview {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}


/* ButtonSection.css */
.additional-fields {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    margin-top: 10px;

}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #ff4d4d;
    border: none;
    background: none;
}

.field-container {
    display: flex;
    flex-direction: column;
    align-items: start;
}



.button-actions {
    display: flex;
    gap: 10px;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-remove {
    background-color: #ff4d4d;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/* ButtonSection.css */
.additional-fields {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    margin-top: 10px;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #ff4d4d;
    background: none;
    border: none;
}



.dropdown {
    padding: 5px;
}

.button-actions {
    display: flex;
    gap: 10px;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-remove {
    background-color: #ff4d4d;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/* ButtonSection.css */
.additional-fields {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    margin:20px;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #ff4d4d;
    background: none;
    border: none;
}



.dropdown {
    padding: 5px;
}

.button-actions {
    display: flex;
    gap: 10px;
}

.btn1add {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-remove {
    background-color: #ff4d4d;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
