/* .modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal-container2 {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button2{
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  text-align: right;
}

.modal-body2 {
  margin-top: 10px;
  text-align: left;
  color: red;
} */
/* Modal Overlay */
.error-modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Container */
.error-modal-container2 {
  background: #ffffff;
  border-radius: 8px;

  width: 400px;
  text-align: center;
  position: relative;
}

/* Close Button */
.error-modal-close2 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Error Icon */
.error-modal-icon2 {
  background-color: #ff6f6f;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin: 0 auto;
margin-top: 40px;
}

/* Modal Title */
.error-modal-title2 {
  color: #ff6f6f;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

/* Modal Message */
.error-modal-message2 {
  color: #333;
  margin: 15px 0;
  font-size: 14px;
}

/* Continue Button */
.error-modal-continue2 {
  background: #ff6f6f;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;

}
