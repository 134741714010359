
.create-whatsapp-template12 {
  
}
.cwt{
  width: 100%;
padding-top: 0px;
  /* background: linear-gradient(to bottom,  #0694c6 10%,  #0694c6 10%, #ecf0f6 50%,#87d1ec 100%, #87d1ec 100%); */
background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);

  overflow-x: hidden;
}
.btcre{
  display: flex;
  align-items: right;
  justify-content: right;
}

.button-create {
    background-color: #00417d; /* Main button color */
    color: #fff; /* Text color */
    border: none; /* Removes default border */
    padding: 10px 20px; /* Adds padding to the button */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Changes cursor to pointer on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  
  
}

.button-create:hover {
    background-color: #00b94f; /* Darker shade on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
    transform: translateY(-2px); /* Lifts the button slightly on hover */
}

.button-create:active {
    background-color: #009f43; /* Slightly darker when clicked */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Reduces shadow when clicked */
    transform: translateY(0); /* Returns button to original position */
}
.part1{
  text-align: left !important;
  
  justify-content: space-between;
color: #00417d;
  padding: 20px 30px;
margin: 20px;

border-radius: 8px;


}
.dar{
  color: #464646;
}
.templates-list12 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

}

.template-card12 {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  width: 250px;
   background-color: #e6f7ff;
   /* background-image: url('../assets/bgd.jpg'); */
}
.bg112{
  background-image: url('../../assets/bgd.jpg');
  height: 400px;
  border-radius: 8px;
}
.template-card12 h2 {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.template-card12 p {
  font-size: 14px;
  margin-bottom: 5px;
}

.error {
  color: red;
}

.click {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.click:hover {
  background-color: #0056b3;
}
/* CreateWhatsappTemplate.css */
.template-card12 {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    background-color: #f9f9f9;
    width: 400px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.5s cubic-bezier(0.25, 0.1, 0.25, 1); /* Smooth and gradual transition */
  
}
.template-card12:hover{
  border-left: 6px solid rgb(0, 199, 0);
  transform: translateY(-5px) scale(1.03); /* Slight lift and scale for smooth effect */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    background-color: #f8f8f8; /* Optional background color change on hover */
}

.template-info {
    margin-top: 16px;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

/* ChatboxPrev.css */
.chatbox-prev {
    border: 1px solid #ddd;
    border-radius: 0px 8px 8px 8px;
    padding: 16px;
    background-color: #e6f7ff;
    margin: 10px;
    width: 90%;
    margin-bottom: 4px;
 
}

.chatbox-header,
.chatbox-body,
.chatbox-footer {
    margin-bottom: 12px;
}

.chatbox-button {
    padding: 8px 16px;
    border: none;
margin: 10px;
    color: #000000;
    border-radius: 8px;
    cursor: pointer;
    width: 90%;
    margin-top: 0px;
}
/* CreateWhatsappTemplate.css */

.template-status {
    font-weight: bold;
 font-size: 0.8rem;
    border-radius: 3px;
    color: #fff; /* Text color */
}

.status-approved {
  color: #4CAF50; /* Green */
}

.status-rejected {
   color: #f44336; /* Red */
}

.status-pending {
color: #f44336; /* Yellow */
}
.temp1name{
  margin-top: 10px;
      white-space: normal; /* Allow text to wrap */
    overflow-wrap: break-word; /* Break words that are too long */
    word-wrap: break-word; /* Ensures long words break into new lines */
    text-align: left; /* Center align text if needed */
    margin-top: 8px; /* Add some spacing from the elements above */
    font-weight: bold; /* Optional styling */
    font-size: 16px; /* Adjust font size as needed */

}
.template-card12 {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure elements are spaced vertically */
    height: 100%; /* Ensure full height for consistent positioning */
}

.category-info {
    margin-top: auto; /* Push the category to the bottom */
    text-align: left; /* Optional: Align text as needed */
}
.tp1{
  background-color: #343534;
  color: white;
padding: 10px;
border-radius: 4px;
}



/* Container for dropdown and preview */
.dropdown-preview-container {
    display: flex;
    align-items: flex-start;
    
    
    padding: 20px;
}

/* Dropdown container */
.dropdown-container {
    flex: 1; /* Adjust as needed */
}

/* Preview container */
.preview-container {
    flex: 2; /* Adjust as needed */
    background-color: #f9f9f9; /* Example background */
    padding: 10px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
}

/* Optional: Style for the dropdown */


/* Style for the template preview */
.template-preview {
    /* Add your desired styles */

    
}

.tp1 {
    margin-bottom: 10px;
}

.temp1name {
    margin-top: 10px;
    font-weight: bold;
}

.template-info p,
.template-preview p {
    margin: 5px 0;
}



.dropdown-preview-container {
   
  display: flex;
  flex-direction: column;
    text-align: left;
    padding: 20px;
    width: 100%;
}

.dropdown-container-mod {
    width: 100%;
   
   
}

.dropdown-mod {
    width: 100%;
    padding: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    outline: none;
    transition: border-color 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown:focus {
    border-color: #888;
}
/* Existing styles for templates-list12, template-card12, etc. */
/* Ensure they don't interfere with the new layout */
.cha11{
  background-image: url('../../assets/bgd.jpg');
  padding: 19px 5px 50px 5px;
    height: 400px;
}
@media (max-width: 768px) {
    .dropdown-preview-container {
        flex-direction: column;
    }
.cwt{
  overflow-x: hidden;
}
    .preview-container {
        margin-top: 20px;
    }

      .dropdown-container {
        max-width: 100%;
    }

    .dropdown {
        font-size: 14px;
    }
    
}
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999;
}

.loader {
    font-size: 20px;
    color: white;
    background-color: #00417d;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.modal-content {
    text-align: center;
    font-size: 16px;
    color: #333;
}
