/* src/components/Login.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
/* .login-container {
    display: flex;
    flex-direction: row;
    height: 70vh;
    font-family: 'Poppins', sans-serif;
    width: 60%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    z-index: 1000;
} */

/* .login1{
   height:30%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #012c5a;
} */
/* .login-container {
    margin-top: 7%;
    display: flex;
    width: 60%; 
    max-width: 100%; 
    background-color: #ffffff; 
    padding: 0px;
    height: 100%;
    border-radius: 10px; 
    box-shadow: rgba(1, 1, 140, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
} */
 
.login1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #00a884 0%, #00a884 30%, #121b22 30%, #121b22 100%);
}

/* Center the login-container */
.login-container {
    display: flex;
    width: 70%; /* Adjust this width according to your needs */
    max-width: 100%;
    background-color: #f7f7f7;
    
    border-radius: 10px;
    box-shadow: rgba(1, 1, 140, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.logo-container {
    position: absolute;
    top: 20px;
    left: 280px;
    display: flex;
    align-items: center;
    color: white;
    padding: 20px;
   
}

.logo-image {
    width: 50px; /* Adjust according to your logo size */
    height: auto;
 
}



.login-left {
    text-align: center;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
}
.login-left {
    flex: 1;
    /* background: url('../assets/images/uisvg.png') no-repeat center center/cover; */
}

.login-image {
    max-width: 100%;
    height: auto;
    padding: 70px;
  
}
.para{
    font-size: 16px;
    z-index: 999;
position: relative;
}
.login-right {
    flex: 1;
    background-color: rgb(255, 254, 254);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    
}

.login-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    color: #00a884;
}

.login-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    color: #00a884;
}

.login-subtitle a {
    color: #0012db;
    text-decoration: underline;
}

.login-form {
    width: 100%;
    max-width: 400px;
}

.form-grouplog{
    margin-bottom: 15px;
}

.form-grouplog label {
    display: block;
    margin-bottom: 5px;
    color: #000000;
    font-weight: 600;
}
.form-grouplog:focus{
  
border: 1px solid black;
}

.form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
}

.form-check-label {
    color: #080808;
}

.btn1, .btn2 {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}

.btn1 {
   background-color:#00a884;
    color: #ffffff;
    font-weight: 500;
}

.btn1:hover {
    background-color: #016a53;
}


.error-message {
    color: #d9534f;
    text-align: center;
    margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .login-left,
    .login-right {
        flex: none;
        width: 100%;
    }

    .login-right {
        padding: 20px;
        border-radius: 5% 5% 0 0;
    }
}

@media (max-width: 768px) {
    .logo-container {
        top: 10px; /* Adjust the top position for mobile */
        left: 10px; /* Move the logo to the left on mobile */
        padding: 10px; /* Reduce padding on mobile */
        flex-direction: row; /* Stack the logo and site name vertically */
        align-items: flex-start;
    }

    .logo-image {
        width: 40px; /* Reduce the size of the logo on mobile */
    }

    .site-name {
        margin-left: 0;
        font-size: 18px; /* Adjust the font size for mobile */
    }
    .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    color: #00a884;
}
.login-image {
    max-width: 100%;
    height: auto;
    padding: 20px;
  
}
.login-container {
    display: flex;
    width: 80%; /* Adjust this width according to your needs */
    max-width: 100%;
    background-color: #f7f7f7;
    
    border-radius: 10px;
    
}
}

/* Media query for smaller mobile devices (like phones) */
@media (max-width: 480px) {
    .logo-container {
        left: 10px; /* Further adjust the left position for smaller screens */
    }

    .logo-image {
        width: 30px; /* Further reduce the size of the logo */
    }

    .site-name {
        font-size: 16px; /* Adjust the font size for smaller screens */
    }
       .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    color: #00a884;
}
.login-image {
    max-width: 100%;
    height: auto;
    padding: 20px;
  
}
.login-container {
    display: flex;
    margin-top:200px;
    width: 100%; /* Adjust this width according to your needs */
    max-width: 100%;
    background-color: #f7f7f7;
    border-radius: 10px;
    
}
}



.modal-overlaylo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;
}

.modal-contentlo {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal-close-btn1 {
    margin-top: 15px;
    padding: 8px 15px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.modal-contentlo button {
    margin-top: 20px;
}
.modal-gif {
    width: 50px; /* Adjust size as needed */
    height: 50px;
    margin-bottom: 10px; /* Space between GIF and heading */
}
.logfb{
    text-decoration: none;
    
}
.spinner {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}
.btn1lo{
    margin-bottom: 0px !important;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
