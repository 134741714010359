/* custom.css */
body.light-theme {
  background-color: #e5ddd5;
  color: #000;
}

body.dark-theme {
  background-color: #18191b;
  color: #fff;
}

.navbar.light-theme {
  background-color: #18191b;
  
}

.navbar.dark-theme {
  background-color: #1c1c1c;

}

.navbar-brand, .nav-link {
  color: #ffffff !important;
}

.nav-link:hover {
  background-color: #128c7e;
  color: #ffffff !important;
}

.container {

  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.btn-primary {
  background-color: #128c7e;
  border-color: #128c7e;
}

.btn-primary:hover {
  background-color: #075e54;
  border-color: #075e54;
}

.form-control {
  background-color: #f0f0f0;
  border: none;
}

.form-control:focus {
  background-color: #ffffff;
  border-color: #128c7e;
  box-shadow: none;
}

body.dark-theme .container {
  background-color: #3a3a3a;
}

body.dark-theme .form-control {
  background-color: #4a4a4a;
  color: #fff;
}

body.dark-theme .form-control:focus {
  background-color: #5a5a5a;
  border-color: #128c7e;
}

body.dark-theme .btn-primary {
  background-color: #128c7e;
  border-color: #128c7e;
}

body.dark-theme .btn-primary:hover {
  background-color: #075e54;
  border-color: #075e54;
}
