/* src/components/MainLayout.css */

.main-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh; /* Ensure the layout covers the full viewport height */
    /* background-color:#18191b; */

 
}

.main-content {
  margin-left: 300px; /* Sidebar width */
padding-top: 20px;
  flex: 1; /* Take up remaining space */
  transition: margin-left 0.3s ease;
  width: 100%;
  justify-content: left;
  align-items: left;
  background: linear-gradient(135deg, #e0f1df, #f7e3ef, #d1e6f3, #e9eff8);
  
}


/* Sidebar specific styles for mobile */
.sidebar {
   /* Width of the sidebar */
  transition: transform 0.3s ease;
  
}

/* Hide sidebar and show hamburger button on mobile */
@media (max-width: 768px) {
  .main-layout {
    flex-direction: column;
  }

  .main-content {
    margin-left: 0; /* Reset margin */
    padding: 0px;
    margin-top: 0px;
  }

  .sidebar {
    position: fixed;
    transform: translateX(-100%); /* Hide sidebar off-screen */
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000; /* Ensure it is above other content */
  }

  .sidebar.open {
    transform: translateX(0); /* Show sidebar */
  }
  .main-content {
  margin-left:0px; /* Sidebar width */
 padding: 0;
 margin: 0;
 
}
}
